<template>
    <div class="nav">
        <div class="nav-display">
            <img class="display-img" src="../assets/logo.png" alt="" @click="indexMet(99)">
            <div class="display-right">
                <div class="right-div" v-for="(item, index) in 3" :key="index" @click="indexMet(index)">
                    {{ index == 0 ? 'ABOUT US' : index == 1 ? 'INVEST' : 'CONTACT US' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        indexMet(index) {
            let id = index == 0 ? 1 : index == 1 ? 3 : index == 2 ? 4 : 0

            this.$emit('navIndex', id)
        }
    }
}
</script>

<style lang="less" scoped>
.nav {
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    .nav-display {
        height: 100%;
        display: flex;
        align-items: flex-start;
        padding: 44px 100px 28px;
        box-sizing: border-box;

        .display-img {
            width: 316px;
            height: 67px;
            cursor: pointer;
        }

        .display-right {
            display: flex;
            margin-left: 58.5px;

            .right-div {
                font-size: 22px;
                font-family: MiSans;
                font-weight: 400;
                color: #020813;
                margin-right: 45.5px;
                cursor: pointer;
            }

        }

    }
}
</style>