<template>
    <div class="index">
        <div class="index-page container">
            <nav-bar @navIndex="handleBannerIn"></nav-bar>

            <div v-swiper:mySwiper="opts" ref="swiperPage">
                <div class="swiper-wrapper" @transitionstart="rollStart" @transitionEnd="rollEnd"
                    :style="{ 'height': height + 'px' }">
                    <div class="swiper-slide" style="height: 100%;">
                        <div class="slide-home">
                            <div class="home-display">
                                <div class="display-left">
                                    <div class="left-title"
                                        :class="{ 'animate__animated': testShow1, 'animate__fadeInDown': testShow1 }">
                                        Investing in <br />Innovation,<br />Nurturing Success</div>
                                    <div class="left-text"
                                        :class="{ 'animate__animated': testShow1, 'animate__fadeInLeft': testShow1 }">
                                        Enduring Vision Capital is a firm that specializes in supporting
                                        early-stage startups<br /> with promising ideas. We provide both financial resources
                                        and
                                        strategic guidance to<br /> help these businesses grow and thrive.</div>
                                </div>

                                <div class="display-right">
                                    <img :class="{ 'animate__animated': testShow1, 'animate__fadeInRight': testShow1 }"
                                        src="../assets/index/index-3.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide" style="height: 100%;">
                        <div class="slide-second">
                            <img class="slide-back" src="../assets/index/index-2.png" />

                            <div class="about">
                                <div class="second-about">
                                    <div class="about-button"
                                        :class="{ 'animate__animated': testShow2, 'animate__fadeInLeft': testShow2 }">
                                        <img src="../assets/index/index-7.png" alt="">
                                        <div class="button-text">ABOUT US</div>
                                    </div>

                                    <div class="about-title"
                                        :class="{ 'animate__animated': testShow2, 'animate__fadeInLeft': testShow2 }">
                                        Investing in Innovation, <br />
                                        Nurturing Success
                                    </div>

                                    <div class="about-text "
                                        :class="{ 'animate__animated': testShow2, 'animate__fadeInLeft': testShow2 }">
                                        Enduring Vision Capital, headquartered in the vibrant hub of<br /> Singapore, is a
                                        dynamic and
                                        forward-thinking Venture Capital<br /> firm with a steadfast commitment to nurturing
                                        the
                                        next<br />
                                        generation of innovative startups across Europe and Asia.
                                    </div>

                                    <div class="about-text"
                                        :class="{ 'animate__animated': testShow2, 'animate__fadeInLeft': testShow2 }">
                                        We pride ourselves on our ability to identify and invest in<br />
                                        ventures that have the potential to redefine industries, solve<br /> global
                                        challenges, and
                                        make a positive impact on society.
                                    </div>

                                    <div class="about-text"
                                        :class="{ 'animate__animated': testShow2, 'animate__fadeInLeft': testShow2 }">
                                        At Enduring Vision Capital, we don't just invest in companies;<br /> we invest in
                                        visionary
                                        ideas, enduring innovation, and a brighte<br /> tomorrow.
                                    </div>
                                </div>

                                <img class="second-img"
                                    :class="{ 'animate__animated': testShow2, 'animate__fadeInRight': testShow2 }"
                                    src="../assets/index/index-6.png" />
                            </div>
                            <img class="slide-right" src="../assets/index/index-4.png" alt="">
                            <img class="slide-left" src="../assets/index/index-5.png" alt="">
                        </div>
                    </div>

                    <div class="swiper-slide" style="height: 100%;">
                        <div class="slide-three">
                            <img class="slide-back" src="../assets/index/index-2.png" />

                            <div class="three">
                                <div class="three-title"
                                    :class="{ 'animate__animated': testShow3, 'animate__fadeInLeft': testShow3 }">
                                    VENTURE<br /> CAPITAL</div>
                                <div class="three-text"
                                    :class="{ 'animate__animated': testShow3, 'animate__fadeInLeft': testShow3 }">Venture
                                    capital encompasses investment endeavors that involve<br />
                                    infusing capital into emerging, promising companies, startups,<br />
                                    and high-growth enterprises. Typically, this venture entails a<br />
                                    high-risk, high-reward dynamic.</div>
                            </div>

                            <img class="slide-right" src="../assets/index/index-4.png" alt="">
                            <img class="slide-left" src="../assets/index/index-5.png" alt="">
                        </div>
                    </div>

                    <div class="swiper-slide" style="height: 100%;">
                        <div class="slide-fourthly">
                            <img class="fourthly-back" src="../assets/index/bg@2x.png" alt="" />

                            <div class="fourthly">
                                <div class="fourthly-block">
                                    <div class="block-header"
                                        :class="{ 'animate__animated': testShow4, 'animate__fadeInUp': testShow4 }">
                                        <img class="header-border" src="../assets/index/index-12.png" alt="">
                                        <img class="header-title" src="../assets/index/index-18.png" alt="">
                                    </div>

                                    <div class="block-display">
                                        <div class="display-0"
                                            :class="{ 'animate__animated': testShow4, 'animate__fadeInLeft': testShow4 }">
                                            <div class="display-0-left">
                                                <img src="../assets/index/index-13.png" alt="">
                                            </div>
                                            <div class="display-0-right">
                                                <div class="right-title">Private Investments</div>
                                                <div class="right-text">
                                                    Our unwavering dedication liesin<br />
                                                    crafting a tailored investment<br />
                                                    approach aimed at delivering<br />
                                                    significant value enhancementto<br />
                                                    the companies within ourportfolio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-0"
                                            :class="{ 'animate__animated': testShow4, 'animate__fadeInRight': testShow4 }">
                                            <div class="display-0-left">
                                                <img src="../assets/index/index-13.png" alt="">
                                            </div>
                                            <div class="display-0-right">
                                                <div class="right-title">Strategic Consultation</div>
                                                <div class="right-text">
                                                    Leveraging our diverse range of skills,<br />
                                                    we collaborate with businesses and<br />
                                                    emerging startups to craft a narrative of synergy,<br /> identify
                                                    suitable
                                                    partners,<br />
                                                    effectively execute transformative<br />
                                                    initiatives.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide" style="height: 100%;">
                        <div class="slide-fifth">
                            <img class="slide-back" src="../assets/index/index-2.png" />

                            <div class="fifth">
                                <div class="second-fifth">
                                    <div class="fifth-button"
                                        :class="{ 'animate__animated': testShow5, 'animate__fadeInLeft': testShow5 }">
                                        <img src="../assets/index/index-7.png" alt="">
                                        <div class="button-text">CONTACT US</div>
                                    </div>

                                    <div class="fifth-title"
                                        :class="{ 'animate__animated': testShow5, 'animate__fadeInLeft': testShow5 }">
                                        ADDRESS
                                    </div>
                                    <div class="fifth-text"
                                        :class="{ 'animate__animated': testShow5, 'animate__fadeInLeft': testShow5 }">
                                        9 Raffles Place #23-05 Republic Plaza<br />
                                        Singapore 048619
                                    </div>

                                    <div class="fifth-display"
                                        :class="{ 'animate__animated': testShow5, 'animate__fadeInLeft': testShow5 }">
                                        <img src="../assets/index/index-8.png" alt="">
                                        <div class="text">info@enduringvision.org</div>
                                    </div>
                                </div>

                                <img class="fifth-img"
                                    :class="{ 'animate__animated': testShow5, 'animate__fadeInRight': testShow5 }"
                                    src="../assets/index/index-9.png" />
                            </div>
                            <img class="slide-right" src="../assets/index/index-4.png" alt="">
                            <img class="slide-left" src="../assets/index/index-5.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var _h = window.innerHeight;
global.height = _h;


import navBar from '../components/nav-bar.vue'

export default {
    components: {
        navBar
    },
    created() {
        console.log('获取当前屏幕高度', document.documentElement.clientHeight)
    },
    data() {
        const self = this
        return {
            activeIndex: 0,
            height: document.documentElement.clientHeight,
            // 区域
            swiperOption: {
            },
            // swiper
            opts: {
                direction: 'vertical',
                slidesPerView: 'auto',//重要的
                mousewheel: true,
                speed: 800,
                grabCursor: true,
                updateOnWindowResize: true,
                height: document.documentElement.clientHeight
            },
            // 触发
            currentPage: 0,
            testShow1: true,
            testShow2: false,
            testShow3: false,
            testShow4: false,
            testShow5: false,
        }
    },
    computed: {
        pageSwiper() {
            return this.$refs.swiperPage.swiper
        },
    },
    mounted() {
        // let vm = this;

        // this.pageSwiper.on('transitionStart', function () {
        //     vm.rollStart(Number(this.activeIndex + 1))
        // })
        // this.pageSwiper.on('transitionEnd', function () {
        //     vm.rollEnd(Number(this.activeIndex + 1))
        // })
    },


    methods: {
        // transitionEnd() {
        //     let swiperIndex = this.$refs.swiperPage.swiper.activeIndex
        //     console.log('下标', swiperIndex)
        //     this.rollStart(swiperIndex)
        //     this.rollEnd(swiperIndex)
        // },


        rollStart() {
            let page = this.$refs.swiperPage.swiper.activeIndex + 1


            this.currentPage = page
            switch (page) {
                case 1:
                    this.testShow1 = true
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                    break
                case 2:
                    this.testShow1 = false
                    this.testShow2 = true
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                    break
                case 3:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = true
                    this.testShow4 = false
                    this.testShow5 = false
                    break
                case 4:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = true
                    this.testShow5 = false
                    break
                case 5:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = true
                    break
            }
        },
        rollEnd() {
            let page = this.$refs.swiperPage.swiper.activeIndex + 1

            this.currentPage = page
            switch (page) {
                case 1:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                case 2:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                    break
                case 3:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                    break
                case 4:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                    break
                case 5:
                    this.testShow1 = false
                    this.testShow2 = false
                    this.testShow3 = false
                    this.testShow4 = false
                    this.testShow5 = false
                    break
            }
        },

        handleScroll() { },
        toTop() {
            if (!('process' in window)) {
                this.pageSwiper.slideTo(0, 0)
            }
        },

        handleBannerIn(index) {
            this.$refs.swiperPage.swiper.slideTo(index, 1000, false)
        },
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>

<style lang="less" scoped >
.swiper-wrapper {
    display: block;

    .swiper-slide {}
}



.slide-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 352px;
}

.slide-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 331px;
    height: 345px;
}

.slide-back {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 0;
}
</style>

<style lang="less" scoped>
.index {
    .index-page {
        .slide-home {
            height: 100%;
            position: relative;

            .home-display {
                display: flex;
                padding: 125px 100px 0;
                box-sizing: border-box;


                .display-left {
                    text-align: left;
                    margin-top: 109px;

                    .left-title {
                        font-size: 75px;
                        font-family: MiSans;
                        font-weight: 400;
                        color: #020F24;
                    }

                    .left-text {
                        font-size: 19.5px;
                        font-family: MiSans;
                        font-weight: 300;
                        color: #020F24;
                        line-height: 31.5px;
                        margin-top: 31px;
                    }
                }


                .display-right {
                    width: 540px;
                    height: 99%;
                    position: absolute;
                    top: 1px;
                    right: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                    }
                }
            }
        }

        .slide-second {
            height: 100%;
            position: relative;

            .about {
                padding: 125px 100px 0;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;

                .second-about {
                    position: relative;

                    .about-button {
                        width: 302px;
                        height: 62.5px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 33px;
                        cursor: pointer;

                        img {
                            width: 302px;
                            height: 62.5px;
                            position: absolute;
                            display: flex;
                        }

                        .button-text {
                            font-size: 25px;
                            font-family: MiSans;
                            font-weight: 400;
                            color: #FFFFFF;
                            position: relative;
                            text-align: center;
                        }
                    }

                    .about-title {
                        font-size: 36px;
                        font-family: MiSans;
                        font-weight: bold;
                        color: #1C275A;
                        text-align: left;
                        margin-top: 16px;
                        margin-bottom: 15px;
                        position: relative;
                        margin-left: 30px;
                    }

                    .about-text {
                        text-align: left;
                        font-size: 20px;
                        font-family: MiSans;
                        font-weight: 400;
                        color: rgba(28, 39, 90, .7);
                        line-height: 29px;
                        margin-bottom: 20px;
                        position: relative;
                        z-index: 2;
                        margin-left: 30px;
                    }

                    .about-title1 {
                        font-size: 25px;
                        font-family: MiSans;
                        font-weight: 600;
                        color: #081434;
                        text-align: left;
                        margin-top: 29px;
                    }

                    .about-text1 {
                        text-align: left;
                        font-size: 26px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #081434;
                        line-height: 35px;
                        margin-top: 20px;
                    }

                    .about-display {
                        display: flex;
                        align-items: center;
                        margin-top: 28px;


                        img {
                            width: 43px;
                            height: 43px;
                            margin-right: 18px;
                        }

                        .text {
                            font-size: 26px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #081434;
                        }
                    }
                }

                .second-img,
                .second-img1 {
                    width: 450px;
                    height: 418px;
                    position: relative;
                    margin-left: 36px;
                    margin-top: 111.5px;
                }

                .second-img1 {
                    margin-top: 33px;
                    width: 525px;
                    height: 489px;
                }
            }
        }

        .slide-three {
            height: 100%;
            position: relative;

            .three {
                padding: 125px 150px 0;
                box-sizing: border-box;
                position: relative;

                .three-title {
                    margin-top: 64px;
                    font-size: 76px;
                    font-family: MiSans;
                    font-weight: bold;
                    color: #1C275A;
                    line-height: 94px;
                    text-align: left;
                }

                .three-text {
                    font-size: 20px;
                    font-family: MiSans;
                    font-weight: bold;
                    color: #2A2C20;
                    line-height: 35px;
                    margin-top: 29px;
                    text-align: left;
                    position: relative;
                    z-index: 1;
                }
            }

        }

        .slide-fourthly {
            height: 100%;
            position: relative;

            .fourthly-back {
                position: absolute;
                top: 153px;
                width: 100%;
                height: 100%;
                display: flex;
            }

            .fourthly-block {
                position: relative;
                width: 100%;
                padding: 173px 100px 0;
                box-sizing: border-box;

                .block-header {
                    width: 674px;
                    height: 98.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    border: 1px solid;

                    .header-border {
                        width: 100%;
                        height: 100%;
                    }

                    .header-title {
                        position: absolute;
                        width: 151px;
                        height: 28px;
                    }
                }

                .block-display {
                    display: flex;
                    justify-content: center;
                    margin-top: 66px;
                    position: relative;

                    .display-0 {
                        display: flex;
                        margin-left: 103px;

                        .display-0-left {
                            width: 26.5px;
                            height: 26.5px;
                            margin-right: 26.5px;
                            margin-top: 15px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .display-0-right {
                            text-align: left;

                            .right-title {
                                font-size: 28px;
                                font-family: DeVinne Txt BT;
                                font-weight: 400;
                                font-style: italic;
                                color: #E3BD84;

                            }

                            .right-text {
                                font-size: 18px;
                                font-family: PingFang SC;
                                font-weight: 300;
                                color: #FFFFFF;
                                line-height: 35px;
                                margin-top: 25px;
                            }
                        }
                    }

                    .display-0:first-child {
                        margin-left: 0;
                    }
                }
            }

        }


        .slide-fifth {
            height: 100%;
            position: relative;

            .fifth {
                padding: 125px 100px 0;
                box-sizing: border-box;
                display: flex;
                justify-content:center;

                .second-fifth {
                    position: relative;

                    .fifth-button {
                        width: 302px;
                        height: 62.5px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 33px;
                        cursor: pointer;

                        img {
                            width: 302px;
                            height: 62.5px;
                            position: absolute;
                            display: flex;
                        }

                        .button-text {
                            font-size: 25px;
                            font-family: MiSans;
                            font-weight: 400;
                            color: #FFFFFF;
                            position: relative;
                            text-align: center;
                        }
                    }

                    .fifth-title {
                        font-size: 25px;
                        font-family: MiSans;
                        font-weight: 600;
                        color: #081434;
                        text-align: left;
                        margin-top: 29px;
                    }

                    .fifth-text {
                        text-align: left;
                        font-size: 26px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #081434;
                        line-height: 35px;
                        margin-top: 20px;
                    }

                    .fifth-display {
                        display: flex;
                        align-items: center;
                        margin-top: 28px;


                        img {
                            width: 43px;
                            height: 43px;
                            margin-right: 18px;
                        }

                        .text {
                            font-size: 26px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #081434;
                        }
                    }
                }

                .fifth-img {
                    margin-top: 33px;
                    width: 525px;
                    height: 489px;
                    position: relative;
                    margin-left: 150px;
                }
            }
        }
    }
}
</style>